.NavBox{
    display: flex;
    
    position:fixed;
    top:0;
    width:100%;
    z-index:100;
    justify-content: space-between;
    background-color: #f0f0f0b5;
    transition: background-color 0.9s ease;
    
    
    
}

.NavBox.small {
    background-color: lightgray;
    padding: 5px;
    position:static;
    top: 0;
  }

.NavItems{
    display: block;
    color: white;
    max-height: 100px;
    /* padding: 1%; */
    margin: 1%;
    
    
    background:transparent;
}

.menuLinks{
    display: flex;
    flex-direction: column;
}

.menuLinks a{
    background: #02241482;
    padding: 0.75rem 5rem;
    width: fit-content;
    color: #ffffff;

    width: 200px;
   
}
.menuLinks a:hover{
    cursor: pointer;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
   
}

.NavBox button{
    background: transparent;
    border: none;
    color: black;
}

