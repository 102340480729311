.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.house-slider{
  background-color: #000;
}

.front-page{
  font-family: "Antic Didone", serif;
  min-height:60vh;
  margin:5% 0;
  padding:5% 5% 1%;
  font-weight: bold;
  line-height: 1.8;
  text-align: center;
  font-size: 36;

}

.App-link {
  color: #61dafb;
}

.big-cards-container{
  display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    flex-wrap: wrap;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* Add this CSS to your global styles or component-specific styles */
@keyframes jumpIn {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.jump-in {
  animation: jumpIn 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
}

.fade-in {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}



/* Right to Left Animation */
@keyframes slideInRight {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.slide-in-right {
  animation: slideInRight 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
}

/* Left to Right Animation */
@keyframes slideInLeft {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.slide-in-left {
  animation: slideInLeft 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
}


